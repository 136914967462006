export const Actions = {
  SHORTCUT: {
    VIEW: "SHORTCUT__VIEW",
    CREATE_CALENDAR_EVENT: "SHORTCUT__CREATE_CALENDAR_EVENT",
    CREATE_OUTCOMES_EVENT: "SHORTCUT__CREATE_OUTCOMES_EVENT",
    CREATE_PRESCRIPTION: "SHORTCUT__CREATE_PRESCRIPTION",
    CREATE_INVITE_PATIENT: "SHORTCUT__CREATE_INVITE_PATIENT",
    SEND_FILL_OUT_SURVEY: "SHORTCUT__SEND_FILL_OUT_SURVEY",
  },
  AINAVIGATOR: {
    VIEW: "AINAVIGATOR__VIEW",
    OPEN_MODAL_PATIENT: "AINAVIGATOR__OPEN_MODAL_PATIENT",
    OPEN_MODAL_SUGGEST_PATIENT: "AINAVIGATOR__OPEN_MODAL_SUGGEST_PATIENT",
    ALERTS: "AINAVIGATOR__ALERTS",
    FOLLOW_UP: "AINAVIGATOR__FOLLOW_UP",
    PATIENTS: "AINAVIGATOR__PATIENTS",
    SELECT_FOLLOW_UP: "AINAVIGATOR__SELECT_FOLLOW_UP",
    OPEN_MODAL_ADD_PATIENT: "AINAVIGATOR__OPEN_MODAL_ADD_PATIENT",
  },
  AINAVIGATOR_MODAL: {
    EVENT_OUTCOMES_MODAL: "AINAVIGATOR_MODAL__EVENT_OUTCOMES_MODAL",
    GO_OUTCOMES: "AINAVIGATOR_MODAL__GO_OUTCOMES",
    EVENT_AGENDA_MODAL: "AINAVIGATOR_MODAL__EVENT_AGENDA_MODAL",
    GO_AGENDA: "AINAVIGATOR_MODAL__GO_AGENDA",
    CHANGE_PROGRAM_STATUS: "AINAVIGATOR_MODAL__CHANGE_PROGRAM_STATUS",
    GO_FOLLOW_UP: "AINAVIGATOR_MODAL__GO_FOLLOW_UP",
    GO_ALERTS: "AINAVIGATOR_MODAL__GO_ALERTS",
    GO_HEALTH: "AINAVIGATOR_MODAL__GO_HEALTH",
    GO_FLORENCE: "AINAVIGATOR_MODAL__GO_FLORENCE",
  },
  OUTCOMES: {
    VIEW: "OUTCOMES__VIEW",
    SELECT_PROGRAM: "OUTCOMES__SELECT_PROGRAM",
    TIMELINE: "OUTCOMES__TIMELINE",
    DASHBOARD: "OUTCOMES__DASHBOARD",
    MEDICAL_GRAPHIC: "OUTCOMES__MEDICAL_GRAPHIC",
    MEDICAL_TABLE: "OUTCOMES__MEDICAL_TABLE",
    ADD_UPDATE: "OUTCOMES__ADD_UPDATE",
  },
  SUVERYS_SECTIONS: {
    VIEW: "SUVERYS_SECTIONS__VIEW" 
  },
  HEALTH: {
    VIEW: "OUTCOMES__VIEW",
    CREATE_MEDICATION: "HEALTH__CREATE_MEDICATION",
    CREATE_VITAL_SIGNS: "HEALTH__CREATE_VITAL_SIGNS",
    REGISTER_VITAL_SIGN: "HEALTH__REGISTER_VITAL_SIGN",
    REPORT_SYMPTOMS: "HEALTH__REPORT_SYMPTOMS",
  },
  FOLLOW_UP: {
    MINIMIZE: "FOLLOW_UP__MINIMIZE",
    MAXIMIZE: "FOLLOW_UP__MAXIMIZE",
    CLOSE: "FOLLOW_UP__CLOSE",
  },
  ALERTS: {
    COMPACT_VIEW: "ALERTS__COMPACT_VIEW",
    ALERTS_VIEW: "ALERTS__ALERTS_VIEW",
    FILTERS: "ALERTS__FILTERS",
    CARD: "ALERTS__CARD",
    SELECT_PATIENT: "ALERTS__SELECT_PATIENT",
  },
  AGENDA: {
    VIEW: "AGENDA__VIEW",
    FILTERS: "AGENDA__FILTERS",
    CREATE_EVENT: "AGENDA__CREATE_EVENT",
    CARD: "AGENDA__CARD",
    SELECT_PATIENT: "AGENDA__SELECT_PATIENT",
  },
  FLORENCE: {
    CHAT: "FLORENCE__CHAT",
    CHAT_GENERAL: "FLORENCE__CHAT_GENERAL",
    CHAT_PATIENTS: "FLORENCE__CHAT_PATIENTS",
    CHAT_GUIDES: "FLORENCE__CHAT_GUIDES",
    CHAT_GUIDES_AND_PATIENTS: "FLORENCE__CHAT_GUIDES_AND_PATIENTS",
    CHAT_MEDICOL: "FLORENCE__CHAT_MEDICOL",
    CHAT_MEDICOL_GENERAL: "FLORENCE__CHAT_MEDICOL_GENERAL",
    CHAT_MEDICOL_PATIENTS: "FLORENCE__CHAT_MEDICOL_PATIENTS",
    CHAT_MEDICOL_GUIDES: "FLORENCE__CHAT_MEDICOL_GUIDES",
    CHAT_MEDICOL_GUIDES_AND_PATIENTS: "FLORENCE__CHAT_MEDICOL_GUIDES_AND_PATIENTS",
    MONACO_ALGORITHMS: "FLORENCE__MONACO_ALGORITHMS",
    MEDICAL_TRANSDUCTOR: "FLORENCE__MEDICAL_TRANSDUCTOR",
  },
  ALGORITHMS: {
    VIEW: "ALGORITHMS__VIEW",
    SELECT_PROGRAM: "ALGORITHMS__SELECT_PROGRAM",
  },
  CHAT: {
    VIEW: "CHAT__VIEW",
  }
};
