import { gql } from "@apollo/client";

export const MUTATION_UPDATE_USER_TO_PROCESS = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS(
    $processId: Int!
    $userId: uuid!
    $doctorId: uuid!
    $stageId: String!
    $descript: String!
    $latestCustomFormId: Int
  ) {
    update_userToProcess(where: {processId: {_eq: $processId}, userId: {_eq: $userId}, doctorId: {_eq: $doctorId}, isAcepted: {_eq: true}}, _set: {
      stageId: $stageId
      descript: $descript
      latestCustomFormId: $latestCustomFormId
    }) {
      returning {
        descript
        doctorId
        id
        isAcepted
        processId
        stageId
        userId
        created_at
        updated_at
        latestCustomFormId
      }
    }
  }
`;

export const MUTATION_UPDATE_USER_TO_PROCESS_ID = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS_ID(
    $userToProcessChanges: userToProcess_set_input, 
    $userToProcessId: uuid!
  ) {
    update_userToProcess_by_pk(pk_columns: {id: $userToProcessId}, _set: $userToProcessChanges) {
      created_at
      descript
      id
      updated_at
    }
  }
`;

export const MUTATION_UPDATE_CUSTOM_FORMS = gql`
  mutation MUTATION_UPDATE_CUSTOM_FORMS(
    $objectsCustomForms: [userToCustomFormField_insert_input!]!
    $customFormId: bigint
    $doctorId: uuid!
    $userId: uuid!
  ) {

    insert_userToCustomFormHistory(
      objects: {
        customFormFields: {
          data: $objectsCustomForms
        }, 
        customFormId: $customFormId, 
        doctorId: $doctorId, 
        userId: $userId, 
        savedByDoctor: true
      }
    ) {
      returning {
        created_at
        customFormId
        doctorId
        id
        updated_at
        userId
        savedByDoctor
        customFormFields {
          created_at
          customFormFieldId
          customFormHistoryId
          doctorId
          id
          key
          userId
          updated_at
          value
        }
      }
    }

  }
`;

export const MUTATION_ACTION_CUSTOM_FORMS_DISPATCH = gql`
  mutation MyMutation(
    $customFormHistoryId: String!, 
    $userId: String!
  ) {
    databaseActionDispatch(customFormHistoryId: $customFormHistoryId, userId: $userId) {
      error_message
      status
    }
  }
`;

export const MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS(
    $processId: Int!
    $userId: uuid!
    $doctorId: uuid!
    $customFormId: bigint
    $objectsCustomForms: [userToCustomFormField_insert_input!]!
    $updatesUserToProcess: userToProcess_set_input
  ) {
    update_userToProcess(
      where: {
        processId: {_eq: $processId}, 
        userId: {_eq: $userId}, 
        doctorId: {_eq: $doctorId}, 
        isAcepted: {_eq: true}
      }, 
      _set: $updatesUserToProcess
    ) {
      returning {
        descript
        customFormFieldChanges
        doctorId
        id
        isAcepted
        processId
        stageId
        userId
        created_at
        updated_at
        latestCustomFormId
        isChecked
      }
    }

    insert_userToCustomFormHistory(
      objects: {
        customFormFields: {
          data: $objectsCustomForms
        }, 
        customFormId: $customFormId, 
        doctorId: $doctorId, 
        userId: $userId
      }
    ) {
      returning {
        created_at
        customFormId
        doctorId
        id
        updated_at
        userId
        customFormFields {
          created_at
          customFormFieldId
          customFormHistoryId
          doctorId
          id
          key
          userId
          updated_at
          value
        }
      }
    }
  }
`;

export const MUTATION_DELETE_USER_TO_PROCESS_BY_ID = gql`
  mutation MUTATION_DELETE_USER_TO_PROCESS_BY_ID(
    $id: uuid!
  ) {
    delete_userToProcess_by_pk(id: $id) {
      id
      isAcepted
      userId
      processId
      doctorId
      created_at
    }
  }
`

export const MUTATION_UPDATE_USER_TO_PROCESS_IS_ACEPTED = gql`
  mutation MyMutation(
    $isAcepted: Boolean
    $id: uuid!
  ) {
    update_userToProcess_by_pk(pk_columns: {id: $id},  _set: {isAcepted: $isAcepted}) {
      created_at
      descript
      doctorId
      id
      isAcepted
      latestCustomFormId
      processId
      stageId
      updated_at
      userId
    }
  }
`

export const MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP($updates: userToProcess_set_input, $userToProcessId: uuid) {
    update_userToProcess(_set: $updates, where: {id: {_eq: $userToProcessId}}) {
      affected_rows
      returning {
        created_at
        customFormFieldChanges
        descript
        doctorId
        id
        isAcepted
        isChecked
        latestCustomFormId
        nextUpdate
        processId
        stageId
        updated_at
        userId
        doctor {
          name
          lastname
        }
        process {
          id
          processName
        }
      }
    }
  }
`