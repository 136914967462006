import { gql } from "@apollo/client";

export const QUERY_GET_SURVEYS_BY_ID_PROCESS = gql`
  query QUERY_GET_CUSTOM_FORM_BY_IDS(
    $processId: Int!
  ) {
    customForm(offset: 1, order_by: {id: asc}, where: {processToCustomForms: {processId: {_eq: $processId}}}) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
        }
      }
    }
  }
`

export const QUERY_GET_ALL_SURVEYS_BY_ID_PROCESS_RANGE_DATES = gql`
  query QUERY_GET_CUSTOM_FORM_BY_IDS(
    $processId: Int!
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    customForm(offset: 1, order_by: {id: asc}, where: {processToCustomForms: {processId: {_eq: $processId}}}) {
      id
      customFormName
      descript
      customFormFrequency(where: {updated_at: {_gte: $initialDate, _lte: $finalDate}}, order_by: {updated_at: desc}) {
        IsComplete
        created_at
        customFormId
        doctorId
        endDateTime
        expectedDateAnswer
        frequency
        frequencyType
        startDateTime
        updated_at
        userId
        id
      }
      userCustomFormHistories(where: {created_at: {_gte: $initialDate, _lte: $finalDate}}, order_by: {created_at: desc}) {
        created_at
        customFormId
        doctorId
        id
        updated_at
        userId
        user {
          name
          lastname
        }
      }
      DoctorToUserProcessBoards {
        customFormId
        doctorId
        id
        typeView
        userToProcessId
      }
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_ALL_SURVEYS_BY_ID_PROCESS = gql`
  query QUERY_GET_ALL_SURVEYS_BY_ID_PROCESS(
    $processId: Int!
  ) {
    customForm(offset: 1, order_by: {id: asc}, where: {processToCustomForms: {processId: {_eq: $processId}}}) {
      id
      customFormName
      descript
      customFormFrequency(order_by: {created_at: desc}) {
        IsComplete
        created_at
        customFormId
        doctorId
        endDateTime
        expectedDateAnswer
        frequency
        frequencyType
        startDateTime
        updated_at
        userId
        id
      }
      userCustomFormHistories(order_by: {created_at: desc}) {
        created_at
        customFormId
        doctorId
        id
        updated_at
        userId
      }
      DoctorToUserProcessBoards {
        customFormId
        doctorId
        id
        typeView
        userToProcessId
      }
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`


export const QUERY_GET_SURVEY_BY_ID = gql`
  query QUERY_GET_SURVEY_BY_ID($userId: uuid, $customFormId: Int!) {
    customForm(where: {id: { _eq: $customFormId }}) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {userId: {_eq: $userId}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

export const  QUERY_GET_SURVEY_WITH_FREQUENCY = gql`
  query QUERY_GET_SURVEY_WITH_FREQUENCY(
    $customFormId: bigint!
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    customForm(where: {id: {_eq: $customFormId}}) {
      id
      customFormName
      descript
      processToCustomForms {
        processId
        process {
          processName
          descript
          id
        }
      }
      userCustomFormHistories(where: {created_at: {_gte: $initialDate, _lte: $finalDate}}, order_by: {created_at: desc}) {
        created_at
        customFormId
        doctorId
        id
        updated_at
        userId
        savedByDoctor
        user {
          email
          documentType
          documentNumber
          lastname
          name
          picture
          id
        }
      }
      customFormFrequency {
        IsComplete
        created_at
        customFormId
        doctorId
        endDateTime
        expectedDateAnswer
        frequency
        frequencyType
        id
        startDateTime
        updated_at
        userId
        customForm {
          id
          customFormName
          descript
        }
        user {
          email
          documentType
          documentNumber
          lastname
          name
          picture
          id
          customFormHistories(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, customFormId: {_eq: $customFormId}}) {
            created_at
            customFormId
            doctorId
            id
            updated_at
            userId
          }
        }
      }
    }
  }
`

export const QUERY_GET_SURVEY_AND_FREQUENCY_BY_USER = gql`
  query QUERY_GET_SURVEY_AND_FREQUENCY_BY_USER($customFormId: bigint!) {
    customForm(where: {id: {_eq: $customFormId}}) {
      id
      customFormName
      descript
      customFormFrequency {
        IsComplete
        created_at
        customFormId
        doctorId
        endDateTime
        expectedDateAnswer
        frequency
        frequencyType
        id
        startDateTime
        updated_at
        userId
      }
    }
  }
` 

export const QUERY_GET_SURVEYS_BY_IDS = gql`
  query QUERY_GET_SURVEYS_BY_IDS( 
    $userId: uuid
    $customFormId: bigint!
    $userToProcessId: uuid!
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      actionName
      destinationTable
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: desc}) {
          created_at
          descript
          id
          customFormFieldChanges
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
        }
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORM_BY_PROCESS = gql`
  query QUERY_GET_CUSTOM_FORM_BY_PROCESS($userId: uuid, $userToProcessId: uuid!, $processId: Int) {
    process(where: {id: {_eq: $processId}}) {
      processName
      id
      icon
      processToCustomForms {
        customFormId
        icon
        id
        customForm {
          id
          customFormName
          descript
          userCustomFormHistories(order_by: {created_at: desc}) {
            created_at
            customFormId
            doctorId
            id
            updated_at
            userId
            customFormFields {
              value
              userId
              updated_at
              key
              id
              doctorId
              customFormHistoryId
              customFormFieldId
              created_at
              customFormField {
                id
                customFormFieldParentId
                descript
                fieldName
                typeFieldValue
              }
            }
          }
          formGroups(order_by: {order: asc}) {
            id
            customFormName
            descript
            DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
              customFormId
              doctorId
              id
              typeView
              userToProcessId
            }
            userToProcesses(where: {userId: {_eq: $userId}}) {
              userId
              descript
              created_at
            }
            userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: desc}) {
              created_at
              descript
              id
              customFormFieldChanges
            }
            customFormFields(order_by: {order: asc}) {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              userToCustomFormFields(order_by: {created_at: desc}) {
                created_at
                doctorId
                customFormHistoryId
                customFormFieldId
                id
                key
                updated_at
                userId
                value
              }
              customFormTypeField {
                isOptions
                value
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
              }
            }
          }
        }
      }
    }
  }
`