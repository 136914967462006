import styled from "styled-components";

export const TransductionActions = styled.div`
  height: 100%;
  margin: 10px 20px 20px 20px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 20px;
  .options-panel {
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E3E3F3;
    &__left {
      display: flex;
      align-items: center;
    }
    &__right {
      display: flex;
    }
    .reset-button {
      margin-left: 10px;
      padding: 5px 10px 5px 7px;
      display: flex;
      align-items: center;
      background-color: #FFFFFF;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      box-shadow: 0px 0px 4px 0px #D8D8EA;
      &__icon {
        margin: 5px 10px 5px 5px;
        font-size: 6px;
      }
      &__text {
        font-family: Nunito Sans;
        font-size: 14px;
        color: #707582;
      }
    }
    .save-button {
      display: flex;
      padding: 0px 10px;
      border-radius: 10px;
      background-color: #D2D2FF;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      &__text {
        font-family: Nunito Sans Bold;
        color: #3E3DA3;
        font-size: 17px;
        margin-right: 5px;
      }
      &__icon-container {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__icon {
        font-size: 12px;
        color: #36369B;
      }
    }
  }
  .select-filter {
    background-color: #F0F0FF;
    /* width: 206px; */
    
    padding: 5px 15px 5px 15px;
    border-radius: 31px;
    align-items: center;
    display: flex;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: space-between;
    color: #36369B;
    font-family: Nunito Sans;
    font-size: 17px;
    &--management-status {
      width: 104px;
    }
    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
  }
`

export const SelectTemplate = styled.div`
  width: 317px;
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: 0px -7px 34.2px 0px #0C0B471C;
  background-color: #fff;
  .select {
    margin-bottom: 20px;
    &__label {
      color: #1F1F52;
      margin-bottom: 5px;
      margin-left: 20px;
      font-size: 18px;
    }
  }
  .apply-template-button {
    padding: 5px 10px 5px 10px;
    border-radius: 33px;
    color: #FFFFFF;
    font-family: Nunito Sans;
    font-size: 17px;
    width: 100%;
    background-color: #00A600;
    border: none;
    outline: none;
    cursor: pointer; 
  }
`;

export const SaveAs = styled.div`
  width: 317px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px -7px 34.2px 0px #0C0B471C;
  background-color: #fff;
  .button-option {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 31px;
    background: #F0F0FF;
    padding: 10px 15px;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    &--open {
      background-color: #FFFFFF;
    }
    &__text {
      color: #36369B;
      font-size: 17px;
    }
    &__icon {
      font-size: 12px;
      margin-right: 5px;
      color: #6C6BCC;
    }
    &__icon-arrow {
      font-size: 13px;
      margin-left: auto;
    }
  }
  .add-note-patient {
    border-radius: 20px;
    background-color: #F7F7FB;
    padding: 10px;
    .select-patient {
      &__label {
        margin-top: 10px;
        margin-left: 20px;
        margin: 10px 0 20px 5px 20px;
        font-size: 18px;
      }
    }
  }
`;