import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./TransductionActions.style";
import Popup from "reactjs-popup";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";

function TransductionActions({
  messages,
  setMessages,
  transducerType
}) {

  const [programSelected, setProgramSelected] = useState();
  const [templateSelected, setTemplateSelected] = useState();
  const [addNotePatient, setAddNotePatient] = useState(false);
  const [addNotePatientSelected, setAddNotePatientSelected] = useState();

  const { t } = useTranslation("global"); 

  const applyTemplate = () => {
    console.log("applyTemplate");
  }

  return (
    <S.TransductionActions>
      <div className="options-panel">
        <div className="options-panel__left">
          <div className="fill-template">
            
            <Popup
              trigger={
                <button className={`select-filter ${"select-filter--actived"}`}>
                  <div className="select-filter__text">
                    Rango de tiempo <b>
                      {
                        // filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) 
                        true 
                      ? "Nivel"
                      : t("globally.all")}
                    </b>
                  </div>
                  <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                </button>
              }
              closeOnDocumentClick
              keepTooltipInside
              position={["bottom center"]}
              nested
            >
              {
                close => (
                  <S.SelectTemplate>
                    <div className="select">
                      <div className="select__label">Select template program</div>
                      <div className="select__selector">
                        <ZaiaSelect
                          placeholder={"Select program"}
                          textColor="#3E3DA3"
                          placeholderTextColor="#36369B"
                          fontSize={"15px"}
                          height={"40px"}
                          errorOption={false}
                          maxHeightList={"200px"}
                          padding={"10px 20px"}
                          borderColor={"#3E3DA3"}
                          borderRadius={"70px"}
                          setValue={(value) => setProgramSelected(value)}
                          value={programSelected}
                          items={
                            [
                              { value: 1, text: "1" },
                              { value: 2, text: "2" },
                              { value: 3, text: "3" },
                              { value: 4, text: "4" },
                              { value: 5, text: "5" }
                            ]
                          //   customFormsList?.map((customForm) => {
                          //   return {
                          //     value: customForm.id,
                          //     text: P.dataTranslation(
                          //       customForm.customFormName,
                          //       language  
                          //     ),
                          //   };
                          // })
                          }
                        />
                      </div>
                    </div>
                    <div className="select">
                      <div className="select__label">Select template program</div>
                      <div className="select__selector">
                        <ZaiaSelect
                          placeholder={t("Select template")}
                          textColor="#3E3DA3"
                          placeholderTextColor="#36369B"
                          fontSize={"15px"}
                          height={"40px"}
                          errorOption={false}
                          maxHeightList={"200px"}
                          padding={"10px 20px"}
                          borderColor={"#3E3DA3"}
                          borderRadius={"70px"}
                          setValue={(value) => setTemplateSelected(value)}
                          value={templateSelected}
                          items={
                            [
                              { value: 1, text: "1" },
                              { value: 2, text: "2" },
                              { value: 3, text: "3" },
                              { value: 4, text: "4" },
                              { value: 5, text: "5" }
                            ]
                          //   customFormsList?.map((customForm) => {
                          //   return {
                          //     value: customForm.id,
                          //     text: P.dataTranslation(
                          //       customForm.customFormName,
                          //       language  
                          //     ),
                          //   };
                          // })
                          }
                        />
                      </div>
                    </div>
                    <button 
                      className="apply-template-button"
                      onClick={() => {
                        applyTemplate();
                      }}
                    >
                      Apply template
                    </button>
                  </S.SelectTemplate>
                )
              }
            </Popup>   
          </div>
          <button className="reset-button">
            <i className="reset-button__icon icon zaia-icono-cerrar"></i>
            <div className="reset-button__text">Reset</div>
          </button>
        </div>
        <div className="options-panel__right">
          
          <Popup
            trigger={
              <button className="save-button">
                <div className="save-button__text">Save as</div>
                <div className="save-button__icon-container">
                  <i className="save-button__icon icon zaia-icono-dots-vertical"></i>
                </div>
              </button>
            }
            closeOnDocumentClick
            keepTooltipInside
            position={["bottom center"]}
            nested
          >
            {
              close => (
                <S.SaveAs>
                  <button className="button-option">
                    <i className="button-option__icon icon zaia-i-doc-normal"></i>
                    <div className="button-option__text">Just copy</div>
                  </button>
                  <button className="button-option">
                    <i className="button-option__icon icon zaia-i-download"></i>
                    <div className="button-option__text">Download as a PDF</div>
                  </button>
                  {
                    !addNotePatient 
                    ? <button className="button-option"
                      onClick={() => {
                        setAddNotePatient(true);
                      }}
                    >
                      <i className="button-option__icon icon zaia-i-download"></i>
                      <div className="button-option__text">As a note for a patient</div>
                      <i className="button-option__icon-arrow icon zaia-icono-dropdown-light"></i>
                    </button>
                    : <div className="add-note-patient">
                      <button className="button-option button-option--open"
                        onClick={() => {
                          setAddNotePatient(false);
                        }}
                      >
                        <i className="button-option__icon icon zaia-i-download"></i>
                        <div className="button-option__text">As a note for a patient</div>
                        <i className="button-option__icon-arrow zaia-i-up"></i>
                      </button>
                      <div className="select-patient">
                        <div className="select-patient__label">Patients</div>
                        <div className="select-patient__container">
                          <ZaiaSelect
                            placeholder={t("Select patient")}
                            textColor="#FFFFFF"
                            placeholderTextColor="#FFFFFF"
                            backgroundColor={"#36369B"}
                            fontSize={"15px"}
                            height={"40px"}
                            errorOption={false}
                            maxHeightList={"200px"}
                            padding={"10px 20px"}
                            borderColor={"#36369B"}
                            borderRadius={"70px"}
                            value={addNotePatientSelected}
                            setValue={(value) => setAddNotePatientSelected(value)}
                            items={
                              [
                                { value: 1, text: "1" },
                                { value: 2, text: "2" },
                                { value: 3, text: "3" },
                                { value: 4, text: "4" },
                                { value: 5, text: "5" }
                              ]
                            //   customFormsList?.map((customForm) => {
                            //   return {
                            //     value: customForm.id,
                            //     text: P.dataTranslation(
                            //       customForm.customFormName,
                            //       language  
                            //     ),
                            //   };
                            // })
                            }
                          />
                        </div>
                      </div>
                      <div className="patient-selected">
                        <div className="patient-selected__left">
                          <img src="" alt="" className="patient-selected__img" />
                          <div className="patient-selected__info">
                            <div className="patient-selected__name">Loren Mendoza</div>
                            <div className="patient-selected__change">Change patient</div>
                          </div>
                        </div>
                        <div className="patient-selected__right">
                          <i className="patient-selected__icon-change icon zaia-i-refresh"></i>
                        </div>
                      </div>
                      <button className="save-patient-note">
                        Save note for this patient
                      </button>
                    </div>
                  }
                </S.SaveAs>
              )
            }
          </Popup>   
        </div>
      </div>
    </S.TransductionActions>
  );
}

export default TransductionActions;
