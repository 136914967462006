import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListOfAlerts.Style";
import { Actions } from "../../../../../utils/actionsIds";

// Pipes
import * as P from "../../../../../utils/pipes";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-alerts.svg`;
const alertsEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-alerts-empty.svg`;

function ListOfAlerts({
}) {

  const {
    t,
    i18n: { language },
  } = useTranslation("global");


  return (
    <S.ListOfAlerts>
      que linda se pone la tarde después
    </S.ListOfAlerts>
  );
}

export default ListOfAlerts;