import styled from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";

export const Alerts = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(243, 243, 252);
  .header {
    padding: 10px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    &__left {
      display: flex;
      align-items: center;
      .patient-filtered {
        align-items: center;
        background-color: #F0F0FF;
        padding: 2.5px 12px 2.5px 2.5px;
        display: flex;
        border-radius: 37.06px;
        &__filtered-by {
          margin: 0 10px 0 5px;
          font-size: 17px;
          color: #36369B;
        }
        &__close {
          border: none;
          outline: none;
          background-color: #36369B;
          color: #FCFCFE;
          font-size: 8px;
          width: 28px;
          height: 28px;
          justify-content: center;
          display: flex;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
        }
        &__text {
          margin-left: 5px;
          &-title {
            /* margin-left: 5px; */
            color: #36369B;
            font-size: 20px;
            font-family: "Nunito Sans";
            strong{
              margin-left: 10px;
            }
          }
        }
      }
      .patient-selector {
        box-sizing: border-box;
        padding: 5.5px 10px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-radius: 31px;
        background: #F0F0FF;
        cursor: pointer;
        color: #36369B;
        &__box-icon {
          border-radius: 50%;
          padding: 2px;
          display: flex;
          margin-right: 10px;
        }
        &__icon {
          border-radius: 50%;
          font-size: 20px;
          color: #36369B;
        }
        &__text {
          text-align: center;
          font-family: "Nunito Sans";
          font-size: 17px;
        }
      } 
      .filter {
        margin-left: 10px;
        &__label {
          color: #7D7C8B;
          font-family: Nunito Sans;
          font-size: 13px;
          &--selector-all {
            color: #36369B;
          }
        }
        .select-filter {
          background-color: #F0F0FF;
          /* width: 206px; */
          height: 34px;
          padding: 5px 15px 5px 15px;
          border-radius: 31px;
          align-items: center;
          display: flex;
          border: none;
          outline: none;
          cursor: pointer;
          justify-content: space-between;
          color: #36369B;
          font-family: Nunito Sans;
          font-size: 17px;
          &--management-status {
            width: 104px;
          }
          &__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
          }
        }
      }
    }
    &__right {
      display: flex;
      .alert-button {
        display: flex;
        margin-left: 10px;
        display: flex;
        height: 31px;
        padding: 5px 20px;
        border-radius: 10px;
        align-items: center;
        border: none;
        outline: none;  
        background-color: #F0F0FF;
        color: #6C6BCC;
        font-family: Nunito Sans;
        font-size: 17px;
        cursor: pointer;
        &--selected {
          background-color: #C6C5FF;
          color: #36369B;
        }
        &__text {
          &--selected {
            font-family: Nunito Sans Bold;
          }
        }
        &__number {
          margin-left: 5px;
        }
      }
    }
  }
  .alerts-container {
    height: calc(100% - 55px);
    display: flex;
    background-color: #fff;
  }
`;


export const TimeRangeSelector = styled.div`
  width: 238px;
  /* height: 225px; */
  padding: 10px 10px 10px 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px #23229D33;
  background-color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    &__label {
      color: #707582;
    }
  }
  .list-ranges {
    height: calc(100% - 29px);
    overflow: auto;
    .range-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 20px 20px 0px 20px;
      &:first-child {
        padding-top: 0px;
      }
      &--actived {
        background-color: #C6C5FF;
        font-family: Nunito Sans Bold;
      }
      &__name {
        font-size: 17px;
        color: #1F1F52;
      }
    }
    .separator {
      margin: 10px 0;
      border-bottom: 1px solid #F0F0FF;
    }
    .choose-range {
      align-items: center;
      background-color: #F8F8FF;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      color: #6C6BCC;
      border-radius: 20px;
      cursor: pointer;
      &__text {
        font-size: 17px;
      }
      &__icon {

      }
    }
  }
`;

export const LevelSelector = styled.div`
  width: 297px;
  height: 225px;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px #23229D33;
  background-color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    &__label {
      color: #707582;
    }
  }
  .list-programs {
    height: calc(100% - 29px);
    overflow: auto;
    .program-item {
      background-color: #F6F6FF;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;
      &--actived {
        background-color: #C6C5FF;
        font-family: Nunito Sans Bold;
      }
      &__name {
        font-size: 14px;
        color: #36369B;
      }
      &__quantity {
        display: flex;
        padding: 0px 10px;
        border-radius: 20px;
        background-color: #8A8AC3;
        align-items: center;
      }
      &__icon {
        font-size: 12px;
        color: #F6F6FF;
        margin-right: 5px;
      }
      &__number {
        font-size: 14px;
        color: #F6F6FF;
      }
    }
  }
`;

export default Alerts;
