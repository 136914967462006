import React from "react";
import * as S from "./TranducerMessagesList.style";

function TranducerMessagesList({
  transducerState,
  transducerType,
  transcription,
  messages
}) {

  if(transducerType === 1)
    return (
      <S.TranducerMessagesList>
        {
          messages.map((message) => {
            return (
              <S.Message
                patient={!message.messagerType}
                transducerState={transducerState}
              >
                <div className="label">{message.messageType ? "Paciente" : "Tú"}</div>
                <div className="box">{message.messageText}</div>
              </S.Message>
            )
          })
        }
      </S.TranducerMessagesList>
    );

  if(transducerType === 2)
    return (
      <S.TranducerMessagesList>
        <S.Message
          patient={false}
          transducerState={transducerState}
          transducerType={transducerType}
        >
          <div className="label">Note</div>
          {
            transcription
            ? <div className="box">{transcription}</div>
            : ""
          }
        </S.Message>
      </S.TranducerMessagesList>
    );
          
}

export default TranducerMessagesList;
