
import styled from "styled-components";

export const ListOfAlerts = styled.div`
  /* width: 100%;
  align-items: flex-start;
  overflow-y: scroll; */
`;


export default ListOfAlerts;