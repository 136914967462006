// Pipes 

// data translations 
export function dataTranslation( data, currentLanguage ) {
  const language = languageSplited(currentLanguage);
  if(data) {
    if(
      language !== 'en' && 
      language !== 'es' &&
      language !== 'fr'
    ) {
      return data['en'];
    } else {
      if(data[`${language}`]) {
        return data[`${language}`] 
      } else {
        return data['en']
      }
    }
  } else {
    return ''
  }
}

export function languageSplited ( currentLanguage ) {
  return currentLanguage.split('-')[0];
}

// dr translations 
// export function drTranslation( gender, currentLanguage ) {
//   // const language = currentLanguage.split('-')[0];
//   // if (language === 'es') {
//   //   if (gender === 'femenine') {
//   //     return 'Dra.'
//   //   } else {
//   //     return 'Dr.'
//   //   }
//   // } else if(language === 'fr') {
//   //   if (gender === 'femenine') {
//   //     return 'Dre.'
//   //   } else {
//   //     return 'Dr.'
//   //   }
//   // } else {
//   //   return 'Dr.'
//   // }
//   return ''
// }

export function drTranslation( gender, currentLanguage ) {
  return ''
}

// date in '01 march 2021' format 
export function longDate ( data, languageFuntion, currentLanguage) {
  const day = new Date(data).getDate();
  const monthNumber = new Date(data).getMonth();
  const year = new Date(data).getFullYear();
  let month = nameMonth(monthNumber, 'L', languageFuntion);
  if (currentLanguage === 'en-US') {
    return (month + ' ' + (day < 10 ? '0' + day : day) + ', ' + year);
  } else {
    return ((day < 10 ? '0' + day : day) + ' ' + month + ', ' + year);
  }
}

// date in '01 mar 2021' Format
export function shortDate ( data, languageFuntion, yearActive, currentLanguage ) {
  const day = new Date(data).getDate();
  const monthNumber = new Date(data).getMonth();
  const year = new Date(data).getFullYear();
  let month = nameMonth(monthNumber, 'S', languageFuntion);
  if (currentLanguage === 'en-US') {
    return (month + ' ' + (day < 10 ? '0' + day : day) + (yearActive ? ' ' + year : '' ));
  } else {
    return ((day < 10 ? '0' + day : day) + ' ' + month + (yearActive ? ' ' + year : '' ));
  }
}

// date in '01/03/2021' 
export function formatNumberDate ( data, yearActive, currentLanguage ) {
  const day = new Date(data).getDate();
  const month = new Date(data).getMonth() + 1;
  const year = new Date(data).getFullYear();
  if (currentLanguage === 'en-US') {
    return ((month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + (yearActive ? '/' + year : '' ));
  } else {
    return ((day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + (yearActive ? '/' + year : '' ));
  }
}

export function formatNumberDateInput ( data ) {
  const day = new Date(data).getDate();
  const month = new Date(data).getMonth() +1;
  const year = new Date(data).getFullYear();
  return (( year + "-" ) + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day));
}

export function formatNumberTimeInput ( data ) {
  const hour = new Date(data).getHours();
  const minutes = new Date(data).getMinutes();
  return ((hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes));
}

// name Month
export function nameMonth(month, size, languageFuntion) {

  const monthsL = [
    languageFuntion('months.januaryL'),
    languageFuntion('months.februaryL'),
    languageFuntion('months.marchL'),
    languageFuntion('months.aprilL'),
    languageFuntion('months.mayL'),
    languageFuntion('months.juneL'),
    languageFuntion('months.julyL'),
    languageFuntion('months.augustL'),
    languageFuntion('months.septemberL'),
    languageFuntion('months.octoberL'),
    languageFuntion('months.novemberL'),
    languageFuntion('months.decemberL')
  ]

  const monthsS = [
    languageFuntion('months.januaryS'), 
    languageFuntion('months.februaryS'),
    languageFuntion('months.marchS'),
    languageFuntion('months.aprilS'),
    languageFuntion('months.mayS'),
    languageFuntion('months.juneS'),
    languageFuntion('months.julyS'),
    languageFuntion('months.augustS'),
    languageFuntion('months.septemberS'),
    languageFuntion('months.octoberS'),
    languageFuntion('months.novemberS'),
    languageFuntion('months.decemberS')
  ]

  return size === 'S' ? monthsS[month] : monthsL[month];
}

// day of week name
export function nameDayWeek(dayWeek, size, languageFuntion) {
  
  const daysWeekL = [
    languageFuntion('weekdays.sundayL'),
    languageFuntion('weekdays.mondayL'),
    languageFuntion('weekdays.tuesdayL'),
    languageFuntion('weekdays.wednesdayL'),
    languageFuntion('weekdays.thursdayL'),
    languageFuntion('weekdays.fridayL'),
    languageFuntion('weekdays.saturdayL')
  ]

  const daysWeekS = [
    languageFuntion('weekdays.sundayS'),
    languageFuntion('weekdays.mondayS'),
    languageFuntion('weekdays.tuesdayS'),
    languageFuntion('weekdays.wednesdayS'),
    languageFuntion('weekdays.thursdayS'),
    languageFuntion('weekdays.fridayS'),
    languageFuntion('weekdays.saturdayS')
  ]

  return size === 'S' ? daysWeekS[dayWeek] : daysWeekL[dayWeek];
}

// get age with the birthdate
export function getAge(birthdate, tillDate) {
  const tillDateCalc = tillDate ? new Date(tillDate) : new Date();
  const cumpleanos = new Date(birthdate);
  let edad = tillDateCalc.getFullYear() - cumpleanos.getFullYear();
  const m = tillDateCalc.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && tillDateCalc.getDate() < cumpleanos.getDate())) {
      edad--;
  }

  return edad;
}

// hours in 12 format '01:00pm'
export function hoursIn12format(hours, minutes) {
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0'+ minutes : minutes;
  hours = hours < 10 ? '0'+ hours : hours;
  const stringTime = hours + ':' + minutes + ' ' + ampm;
  return stringTime;
}

// hours in 24 format '13:00'
export function hoursIn24format(hours, minutes, seconds) {
  minutes = minutes < 10 ? '0'+ minutes : minutes;
  hours = hours < 10 ? '0'+ hours : hours;
  seconds = seconds < 10 ? '0'+ seconds : seconds;
  const stringTime = hours + ':' + minutes + (seconds ? ':' + seconds : '');
  return stringTime;
}

// get percentage
export function getPercentage(quantity, total, percentTotal=100) {
  if((!quantity && !total) || total === 0 || percentTotal === 0 ) {
    return 0;
  } else {
    const percentage = (quantity * percentTotal) / total;
    if(percentage !== 100 ) {
      if(percentage % 1 === 0 ) {
        return percentage;
      } else {
        return Number(percentage.toFixed(1));
      }
    } else {
      return 100;
    }
  }
}

// capitalize first letter of a sentence
export function firstLetterUpper(phrase) {
  let firstLetter = phrase[0];
  return firstLetter.toUpperCase() + phrase.substring(1);
}

// lowercase the first letter of a sentence
export function firstLetterLower(phrase) {
  let firstLetter = phrase[0];
  return firstLetter.toLowerCase() + phrase.substring(1);
}

// get time depend of the day (today: return hour, in the same week: return weekday name, other: return date XX/XX/XXXX)
export function getDynamicTime(date, languageFuntion, currentLanguage) {
  const now = new Date(Date.now());
  const weekDayNow = now.getDay();

  const nowDay = now.setHours(0,0,0,0)
  const yesterday = new Date(now.setDate(now.getDate() - 1)).setHours(0,0,0,0);
  const firstDayWeek = new Date(now.setDate(now.getDate() - (weekDayNow - 1))).setHours(0,0,0,0);

  const newDate = new Date(date).getTime();
  
  if (newDate >= nowDay) {
    return hoursIn12format(new Date(newDate).getHours(), new Date(newDate).getMinutes())
  } else if (newDate >= yesterday) {
    return languageFuntion('globally.yesterday');
  } else if (newDate >= firstDayWeek) {
    return nameDayWeek(new Date(newDate).getDay(), 'L', languageFuntion);
  } else {
    return formatNumberDate(new Date(newDate), true, currentLanguage);
  }
}

// get body surface calc
export function getBodySurface(height, weight) {
  return parseFloat(Math.sqrt((weight * height)/ 3600)).toFixed(2);
}

// vital sign conversion by type

export function convertVitalSign(vitalSign, measure, unit) {

  let measureConverted = measure;

  switch(vitalSign) {
    case 'weight': 
     if(unit === 'Lb') measureConverted = measure * 2; break;
    case 'temperature':
      if(unit === 'F') measureConverted = ((measure * 1.8) + (32)).toFixed(2); break;
    case 'glucometry': 
      if(unit === 'mgdl') measureConverted = measure * 0.055; break;
    default: measureConverted = measure; break;
  }

  return measureConverted;
}

// hex color with opacity 

export function hexColorOpacity(colorName, opacity) {
  const color = getComputedStyle(document.body).getPropertyValue(colorName);;
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

// opacity to hex code

export function getHexOpacity(opacity) {
  return Math.floor(opacity * 255).toString(16).padStart(2, 0).toUpperCase();
}

// get language for mic setting

export function getMicLanguage(currentLanguage) {
  const language = currentLanguage.split('-')[0];
  if (language === 'es') {
    return 'es-ES'
  } else if (language === 'fr') {
    return 'fr-FR'
  } else {
    return 'en-US'
  } 
}

// remove accent from a text
export function removeAccent(text) {
  return text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}